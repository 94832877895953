import { IChatMessage } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { FC } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import AudioMessage from '../AudioMessage';
import IncomingImageMessage from './IncomingImageMessage';
import IncomingMessageFooter from './IncomingMessageFooter';
import IncomingTextMessage from './IncomingTextMessage';
import IncomingVideoMessage from './IncomingVideoMessage';

interface IIncomingMessage {
  id: number;
  message: IChatMessage;
  messageHeader?: string | null;
  collapseWithNext: boolean;
  className?: string;
}

const IncomingMessage: FC<IIncomingMessage> = ({
  message,
  messageHeader,
  collapseWithNext,
  className,
  id
}) => {
  const renderMessage = () => {
    if (message.title || message.text) {
      return (
        <SMessage className={cn(className, { collapseWithNext })}>
          <IncomingTextMessage
            title={message.title}
            text={message.text}
            link={message.link}
          />
        </SMessage>
      );
    }

    switch (message.media?.type) {
      case 'video':
        return (
          <SMessage className={cn(className, { collapseWithNext })}>
            <SVideoContent videoURL={message.media.url} />
          </SMessage>
        );

      case 'audio':
        return (
          <SAudioMessageWrapper className={cn({ collapseWithNext })}>
            <SAudioMessage audioURL={message.media.url} id={`sender_message-${id}`} />
          </SAudioMessageWrapper>
        );

      case 'image':
        return (
          <SMessage className={cn(className, { collapseWithNext })}>
            <SIncomingImageMessage
              image={message.media.payload.imageURL}
              smallImage={message.media.payload.mobileImageURL}
              cover={message.media.payload.coverImg}
              alt={message.media.payload.imageAlt || ''}
            />
          </SMessage>
        );

      case 'giphy':
        return (
          <SMessage className={cn(className, { collapseWithNext })}>
            <SIncomingImageMessage
              giphyImage={message.media.payload}
              responsiveGiphy={false}
              alt={message.media.payload?.title || ''}
            />
          </SMessage>
        );

      default:
        return null;
    }
  };

  return (
    <SWrapper dir="ltr" className={cn({ collapseWithNext })}>
      {messageHeader ? <SMessageHeader>{messageHeader}</SMessageHeader> : null}

      {renderMessage()}

      {message.author?.name && !collapseWithNext ? (
        <IncomingMessageFooter
          authorName={message.author.name}
          authorInitials={message.author.initials}
          authorAvatar={message.author.avatar}
          createdAt={message.createdAt}
        />
      ) : null}
    </SWrapper>
  );
};

export default IncomingMessage;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-inline-start: 44px;
  margin-block-end: 20px;

  &.collapseWithNext {
    border-bottom-left-radius: 10px;
    margin-block-end: 0;
  }

  ${customMediaQuery('tablet')} {
    margin-inline-start: 62px;
  }
`;

const SMessageHeader = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1rem;
  color: var(--heading-text-color);
  opacity: 0.5;
  margin: 0 0 8px;

  ${customMediaQuery('tablet')} {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const SAudioMessageWrapper = styled.div`
  margin-block-end: 8px;
  background: var(--assignments-message-in-background);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  width: 100%;
  max-width: 90%;

  &.collapseWithNext {
    border-bottom-left-radius: 10px;
  }

  ${customMediaQuery('tablet')} {
    max-width: 85%;
  }
`;

const SAudioMessage = styled(AudioMessage)`
  & > div {
    background: transparent;
    width: 90%;
  }

  ${customMediaQuery('tablet')} {
    & > div {
      width: 85%;
    }
  }
`;

const SMessage = styled.div`
  background: var(--assignments-message-in-background);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  padding: 16px;
  width: 100%;
  max-width: 90%;
  margin-block-end: 4px;

  &.collapseWithNext {
    border-bottom-left-radius: 10px;
  }

  ${customMediaQuery('tablet')} {
    padding: 20px;
    max-width: 85%;
    margin-block-end: 8px;
  }
`;

const SIncomingImageMessage = styled(IncomingImageMessage)`
  margin: -14px;
  width: calc(100% + 28px);

  ${customMediaQuery('desktop')} {
    margin: -16px;
    width: calc(100% + 32px);
  }
`;

const SVideoContent = styled(IncomingVideoMessage)`
  margin: -12px;
  overflow: hidden;
  width: calc(100% + 24px);
  border-radius: 10px;

  ${customMediaQuery('tablet')} {
    margin: -16px;
    width: calc(100% + 32px);
  }
`;
