import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { seconds2timestring } from 'common/utils/seconds2timestring';
import { Translate } from 'i18n';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { customMediaQuery, SPrimaryButton } from 'students/views/shared/styled';
import { AudioRecorder } from 'common/modules/AudioRecorder';
import crossIcon from 'students/views/shared/assets/icons/close_icon.svg';

import ShortEssayConfirmationModal from '../../../ShortEssayConfirmationModal';

interface IReplyAudioRecorder {
  handleRecordSend: (record: Blob | null) => void;
  minimumRecordingTime: number;
  isLoading: boolean;
  disabledSubmit?: boolean;
  onClose?: () => void;
  className?: string;
}

const ReplyAudioRecorder: FC<IReplyAudioRecorder> = ({
  handleRecordSend,
  minimumRecordingTime,
  isLoading,
  disabledSubmit,
  onClose,
  className
}) => {
  const [showSendButton, setShowSendButton] = useState<boolean>(false);
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [currentDuration, setCurrentDuration] = useState('0');

  const handleOnRecordChange = useCallback(
    (record: Blob | null) => {
      if (record) {
        setRecordedAudio(record);
        setShowSendButton(true);
      }
    },
    [setRecordedAudio, setShowSendButton]
  );

  const handleOnRecordSend = useCallback(async () => {
    const audioContext = new AudioContext();
    const audioArrayBuffer = await recordedAudio?.arrayBuffer();

    if (audioArrayBuffer) {
      audioContext.decodeAudioData(audioArrayBuffer, (buffer) => {
        setCurrentDuration(buffer.duration.toFixed(0));

        if (buffer.duration < minimumRecordingTime) {
          setShowModal(true);
        } else {
          handleRecordSend(recordedAudio);
        }
      });
    } else {
      handleRecordSend(recordedAudio);
    }
  }, [handleRecordSend, recordedAudio, minimumRecordingTime]);

  return (
    <SWrapper
      layout
      className={cn(className)}
      initial={{ opacity: 0, scaleY: 0 }}
      animate={{ opacity: 1, scaleY: 1 }}
      dir="ltr"
    >
      <AudioRecorder
        onRecordChange={handleOnRecordChange}
        customOptions={{ height: 60, barMinHeight: 1, normalize: true }}
      />

      {showSendButton ? (
        <SSendBtn disabled={disabledSubmit || isLoading} onClick={handleOnRecordSend}>
          <Translate str="frontend.lesson_task.tasks.check.assignment.send_to_review" />
        </SSendBtn>
      ) : (
        <SMinimumRecordingTime>
          <Translate
            str="frontend.lesson_task.tasks.check.assignment.minimal_recording_time"
            params={{
              minimalRecordingTime: seconds2timestring(minimumRecordingTime)
            }}
          />
        </SMinimumRecordingTime>
      )}

      {typeof onClose === 'function' ? (
        <SCloseBtn onClick={onClose}>
          <UrlIcon
            url={crossIcon}
            height="16px"
            width="16px"
            color="var(--heading-text-color)"
          />
        </SCloseBtn>
      ) : null}

      <ShortEssayConfirmationModal
        onSend={() => {
          handleRecordSend(recordedAudio);
        }}
        show={showModal}
        close={() => setShowModal(false)}
        message={
          <Translate
            str="frontend.lesson_task.tasks.check.assignment.recording_does_not_have_enough_duration"
            params={{
              minDurationNumber: `${minimumRecordingTime}s`,
              currentDurationNumber: `${currentDuration}s`
            }}
          />
        }
        continueBtnText={
          <Translate str="frontend.lesson_task.tasks.check.assignment.continue_recording" />
        }
      />
    </SWrapper>
  );
};

export default ReplyAudioRecorder;

const SWrapper = styled(motion.div)`
  transform-origin: bottom;
  width: 100%;
  background: var(--assignments-reply-input-background);
  border-radius: 14px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const SMinimumRecordingTime = styled.p`
  margin: 60px 0 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  color: var(--sub-text-color);
  opacity: 0.8;
`;

const SSendBtn = styled(SPrimaryButton)`
  margin: 30px 0 0;
  min-height: 3rem;
  font-size: 1rem;
  transition: background-color 0.3s;
  width: 100%;

  ${customMediaQuery('tablet')} {
    min-height: 3.25rem;
    width: 200px;
  }
`;

const SCloseBtn = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 4px;
  opacity: 0.4;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
