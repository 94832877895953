import {
  IChatMessage,
  lessonSelectors,
  tAssignmentActions,
  tRequestStatus,
  tUseAssignmentReturnValue
} from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { t } from 'i18n';
import {
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { Spinner } from 'students/views/shared/components/Spinner';
import { customMediaQuery } from 'students/views/shared/styled';
import styled, { css } from 'styled-components';

import { useTypedSelector } from 'students/stores/_utils';
import {
  FeedbackMessage,
  IncomingMessage,
  OutgoingAudioMessage,
  OutgoingTextMessage,
  ReactionBlock,
  ReplyAudioRecorder,
  ReplyTextarea,
  UpdateResponseBlock
} from './components';

interface IMessenger {
  assignment: tUseAssignmentReturnValue;
  status: tRequestStatus;
  actions: tAssignmentActions & { onSkip?: () => void };
  className?: string;
  submitDisabled?: boolean;
  studentCanAddNewAnswer?: boolean;
  lastStudentMessage?: IChatMessage | null;
  fallbackMessageRender?: (
    message: tUseAssignmentReturnValue['messages'][number]
  ) => ReactNode;
  initialShowReplyBlock?: boolean;
  noReplyMode?: boolean;
  onReplyActivityChange?: (active: boolean) => void;
  footer?: ReactNode;
  textReplyError?: ReactNode;
  additionalLastMessage?: ReactNode;
  variant?: 'dark' | 'light';
}

export type tMessengerRef = {
  openReply: () => void;
  closeReply: () => void;
};

const Messenger = forwardRef<tMessengerRef, IMessenger>(
  (
    {
      assignment,
      status,
      actions,
      submitDisabled,
      studentCanAddNewAnswer,
      lastStudentMessage,
      className,
      fallbackMessageRender,
      initialShowReplyBlock,
      noReplyMode,
      onReplyActivityChange,
      footer,
      textReplyError,
      additionalLastMessage,
      variant = 'dark'
    },
    ref
  ) => {
    const lesson = useTypedSelector(lessonSelectors.selectLesson);
    const [showReplyBlock, setShowReplyBlock] = useState<boolean>(
      initialShowReplyBlock ?? false
    );
    const [inputAnswerDefaultMessage, setInputAnswerDefaultMessage] =
      useState<string>('');

    useImperativeHandle(ref, () => ({
      openReply() {
        setShowReplyBlock(true);
      },
      closeReply() {
        setShowReplyBlock(false);
      }
    }));

    const showReply = useCallback(() => {
      setShowReplyBlock(true);
      onReplyActivityChange?.(true);
    }, [onReplyActivityChange]);

    const hideReply = useCallback(() => {
      setShowReplyBlock(false);
      onReplyActivityChange?.(false);

      // setFooterState('none');
      setInputAnswerDefaultMessage('');
    }, [onReplyActivityChange]);

    const handleOnTextSend = useCallback(
      (answer: string) => {
        setInputAnswerDefaultMessage(answer);
        actions.submitTextAnswer(answer).catch((_error) => {
          // setInputAnswerDefaultMessage(answer);
          setShowReplyBlock(true);
        });
        setShowReplyBlock(false);
      },
      [actions]
    );

    const handleOnEdit = useCallback(
      (message: IChatMessage) => {
        if (message.text) {
          setInputAnswerDefaultMessage(message.text ?? '');
        }

        showReply();
      },
      [setInputAnswerDefaultMessage, showReply]
    );

    const handleOnRecordSend = useCallback(
      (record: Blob | null) => {
        if (record) {
          const data = new FormData();
          data.append('document[audio]', record, 'audio.mp3');

          actions.submitAudioAnswer(data);
        }

        hideReply();
      },
      [actions, hideReply]
    );

    const isStudentReplyLast = useMemo(
      () => assignment.messages[assignment.messages.length - 1].from === 'outgoing',
      [assignment.messages]
    );

    const firstOfLastTeacherMessages = useMemo(() => {
      const reversedMessages = [...assignment.messages].reverse();
      const firstTeacherMessageIndex = reversedMessages.findIndex(
        (message: IChatMessage) => message.from === 'incoming'
      );

      if (firstTeacherMessageIndex > -1) {
        const teacherMessages = [];
        let index = firstTeacherMessageIndex;

        while (
          reversedMessages[index] &&
          reversedMessages[index].from === 'incoming' &&
          (teacherMessages[teacherMessages.length - 1]
            ? teacherMessages[teacherMessages.length - 1].author?.name ===
              reversedMessages[index].author?.name
            : true)
        ) {
          teacherMessages.push(reversedMessages[index]);
          index += 1;
        }

        return teacherMessages[teacherMessages.length - 1];
      }

      return null;
    }, [assignment.messages]);

    const renderMessage = (
      message: IChatMessage,
      index: number,
      messages: IChatMessage[]
    ) => {
      const nextMessage = messages[index + 1];
      const collapseWithNext =
        !!nextMessage &&
        message.from === nextMessage.from &&
        message.author?.name === nextMessage.author?.name;
      const showEdit = message.editable || false;
      let messageHeader = null;

      switch (message.from) {
        case 'incoming':
          if (
            assignment.status === 'rejected' &&
            message.key === firstOfLastTeacherMessages?.key
          ) {
            messageHeader = t(
              'frontend.member.assignments.teacher_asked_to_update_assignment'
            );
          }

          return (
            <IncomingMessage
              id={index}
              key={message.key}
              message={message}
              messageHeader={messageHeader}
              collapseWithNext={collapseWithNext}
            />
          );

        case 'outgoing':
          if (message.media?.type === 'audio') {
            return (
              <OutgoingAudioMessage
                key={message.key}
                message={message}
                showEdit={showEdit}
                onEdit={handleOnEdit}
                collapseWithNext={collapseWithNext}
              />
            );
          }

          return (
            <OutgoingTextMessage
              key={message.key}
              message={message}
              showEdit={showEdit}
              onEdit={handleOnEdit}
              collapseWithNext={collapseWithNext}
            />
          );

        default:
          if (typeof fallbackMessageRender === 'function') {
            return fallbackMessageRender(message);
          }

          return null;
      }
    };

    const renderReplyBlock = () => {
      const allowSameAnswerSubmit = status === 'submit_failed';
      switch (assignment.inputType) {
        case 'text':
          return (
            <SReplyTextarea
              onSend={handleOnTextSend}
              minimumWordsCount={assignment.minimumAnswerLength}
              defaultValue={inputAnswerDefaultMessage}
              isLoading={status === 'submit_loading'}
              forbidSameAnswer={!allowSameAnswerSubmit}
              onClose={hideReply}
              disabledSubmit={submitDisabled}
              replyError={textReplyError}
              withNbSupportCharacters={
                lesson?.languageCode === 'nb' || lesson?.languageCode === 'nn'
              }
            />
          );

        case 'audio':
          return (
            <SReplyAudioRecorder
              handleRecordSend={handleOnRecordSend}
              minimumRecordingTime={assignment.minimumAnswerLength}
              isLoading={status === 'submit_loading'}
              onClose={hideReply}
              disabledSubmit={submitDisabled}
            />
          );

        case 'file':
          /* [TODO] Temporary null, until we have this functionality on the back-end side */
          return null;
        default:
          return null;
      }
    };

    const renderReactionBlock = () => {
      return (
        <SReactionBlock
          rating={assignment.rating}
          onSendRating={actions.submitRate}
          isLoading={status === 'rate_loading'}
        />
      );
    };

    const renderUpdateResponseBlock = () => {
      return (
        <UpdateResponseBlock
          onUpdate={() => (lastStudentMessage ? handleOnEdit(lastStudentMessage) : null)}
        />
      );
    };

    const renderFeedbackMessageBlock = () => {
      if (assignment.owner.name) {
        return (
          <SFeedbackMessage
            avatar={assignment.owner.avatar}
            name={assignment.owner.name}
            initials={assignment.owner.initials}
            responseTime={assignment.estimatedReviewTime}
          />
        );
      }

      return null;
    };

    const renderMessengerFooter = () => {
      if (status === 'fetch_loading') {
        return <Spinner />;
      }

      if (!noReplyMode && showReplyBlock) {
        return <AnimatePresence>{renderReplyBlock()}</AnimatePresence>;
      } else if (
        assignment.status === 'approved' ||
        assignment.messages[assignment.messages.length - 1].from === 'incoming:ai'
      ) {
        return renderReactionBlock();
      } else if (studentCanAddNewAnswer) {
        return renderUpdateResponseBlock();
      } else if (
        assignment.status === 'pending' &&
        isStudentReplyLast &&
        assignment.owner.name
      ) {
        return renderFeedbackMessageBlock();
      } else {
        return null;
      }
    };

    return (
      <SWrapper className={cn(className)} isLight={variant === 'light'}>
        <SMessagesBlock>{assignment.messages.map(renderMessage)}</SMessagesBlock>

        {additionalLastMessage}

        {renderMessengerFooter()}

        {footer}
      </SWrapper>
    );
  }
);

Messenger.displayName = 'Messenger';

export default Messenger;

const SWrapper = styled.div<{ isLight: boolean }>`
  background: var(--assignments-card-background-mobile);
  box-shadow: var(--assignments-card-shadow);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-block-end: -1.25rem;
  padding: 28px 12px;
  position: relative;
  flex-grow: 1;

  ${({ isLight }) => (isLight ? LightVariantVars : '')};

  ${customMediaQuery('tablet')} {
    background: var(--assignments-card-background);
    margin: 0;
    width: 100%;
    padding: 30px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    flex-grow: initial;
  }
`;

const SMessagesBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SReplyTextarea = styled(ReplyTextarea)`
  margin: 16px -10px -28px;
  width: calc(100% + 22px);

  ${customMediaQuery('tablet')} {
    margin: 24px 0 0;
    width: 100%;
  }
`;

const SReplyAudioRecorder = styled(ReplyAudioRecorder)`
  margin-block-start: 24px;
`;

const SFeedbackMessage = styled(FeedbackMessage)`
  margin-block-start: 32px;
`;

const SReactionBlock = styled(ReactionBlock)`
  width: 100%;
  margin-block-start: 2rem;
`;

export const LightVariantVars = css`
  --page-background-color: rgb(240, 240, 245);
  --page-background-gradient: #f0f0f5;
  --card-background-color: #ffffff;
  --card-transparent-background-color: #ffffff80;
  --card-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  --card-inner-background-color: #e6e6f0;
  --heading-text-color: #2d2d3a;
  --heading-text-color-1: #2d2d3a80;
  --paragraph-text-color: #2d2d3a;
  --sub-text-color: #989bad;
  --modal-background: #ffffff;
  --modal-hover-background: rgba(224, 224, 232, 0.45);
  --modal-active-background: rgb(248, 248, 255);
  --assignments-card-background: linear-gradient(
    180deg,
    #ececf7 0%,
    rgba(236, 236, 247, 0.9) 100%
  );
  --assignments-card-background-mobile: linear-gradient(
    180deg,
    #ececf7 0%,
    rgba(236, 236, 247, 0.9) 100%
  );
  --assignments-card-shadow: 0 0 10px rgba(11, 63, 79, 0.26);
  --assignments-message-in-background: #ffffff;
  --assignments-message-in-background-hover: rgba(255, 255, 255, 0.5);
  --assignments-message-out-background: #d9d7e5;
  --assignments-tag-background: rgba(0, 0, 0, 0.2);
  --assignments-feedback-message-background: #ffffff;
  --assignments-reply-input-background: rgba(193, 192, 210, 0.5);
  --assignments-skip-button-background: rgba(45, 45, 58, 0.1);
  --assignments-skip-button-color: rgba(45, 45, 58, 0.8);
  --wave-audio-player-icon-background: transparent;
  --lingu-focus-color: #0094c5;
`;
